<template>
  <div class="app auth-page" id="auth" :style="authStyle">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="loading" class="loader-container">
            <loader></loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authMixin from '../../mixins/auth';
export default {
  mixins: [authMixin],
  mounted() {},
  created() {
    let tokenForAdminLogin = this.$route.query['token'];
    if (tokenForAdminLogin) {
      this.$store.dispatch('auth/logout', false);
      this.loading = true;
      this.loginWithAdminToken(tokenForAdminLogin);
      return;
    } else {
      this.$router.push('login');
    }
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import './auth.scss';
</style>
